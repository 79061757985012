export default defineI18nConfig(() => ({
  silentTranslationWarn: true,
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'EUR'
      },
      percent: {
        style: 'percent'
      }
    },
    fr: {
      currency: {
        style: 'currency',
        currency: 'EUR'
      },
      percent: {
        style: 'percent'
      }
    },
    'pt-BR': {
      currency: {
        style: 'currency',
        currency: 'BRL'
      },
      percent: {
        style: 'percent'
      }
    }
  },
  datetimeFormats: {
    en: {
      short: {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
      },
      weekdayYearless: {
        month: '2-digit',
        day: '2-digit',
        weekday: 'short'
      },
      dayYearless: {
        month: '2-digit',
        day: '2-digit'
      },
      shortWithHour: {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric'
      },
      shortWithOnlyDay: {
        day: '2-digit'
      },
      shortWithoutDay: {
        year: '2-digit',
        month: '2-digit'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour12: false
      },
      longWithTime: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric'
      },
      longYearless: {
        month: 'long',
        day: 'numeric',
        weekday: 'long'
      },
      full: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      },
      day: {
        weekday: 'long'
      },
      dayAndMonth: {
        day: 'numeric',
        month: 'long'
      },
      dayAndMonthShort: {
        day: 'numeric',
        month: 'short'
      },
      dayAndMonthLong: {
        weekday: 'short',
        day: 'numeric',
        month: 'short'
      },
      timeOnly: {
        hour: 'numeric',
        minute: 'numeric'
      }
    },
    fr: {
      short: {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
      },
      weekdayYearless: {
        month: '2-digit',
        day: '2-digit',
        weekday: 'short'
      },
      dayYearless: {
        month: '2-digit',
        day: '2-digit'
      },
      shortWithHour: {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric'
      },
      shortWithOnlyDay: {
        day: '2-digit'
      },
      shortWithoutDay: {
        year: '2-digit',
        month: '2-digit'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'long'
      },
      longWithTime: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric'
      },
      longYearless: {
        month: 'long',
        day: 'numeric',
        weekday: 'long'
      },
      full: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      },
      day: {
        weekday: 'long'
      },
      dayAndMonth: {
        day: 'numeric',
        month: 'long'
      },
      dayAndMonthShort: {
        day: 'numeric',
        month: 'short'
      },
      dayAndMonthLong: {
        weekday: 'short',
        day: 'numeric',
        month: 'short'
      },
      timeOnly: {
        hour: 'numeric',
        minute: 'numeric'
      }
    },
    'pt-BR': {
      short: {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
      },
      weekdayYearless: {
        month: '2-digit',
        day: '2-digit',
        weekday: 'short'
      },
      dayYearless: {
        month: '2-digit',
        day: '2-digit'
      },
      shortWithHour: {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric'
      },
      shortWithOnlyDay: {
        day: '2-digit'
      },
      shortWithoutDay: {
        year: '2-digit',
        month: '2-digit'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'long'
      },
      longWithTime: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric'
      },
      longYearless: {
        month: 'long',
        day: 'numeric',
        weekday: 'long'
      },
      full: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      },
      day: {
        weekday: 'long'
      },
      dayAndMonth: {
        day: 'numeric',
        month: 'long'
      },
      dayAndMonthLong: {
        weekday: 'short',
        day: 'numeric',
        month: 'short'
      },
      timeOnly: {
        hour: 'numeric',
        minute: 'numeric'
      }
    }
  }
}))
